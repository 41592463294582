<template>
  <div class="infotronik_strategia_gol_riga">
    <div class="goal">
      <label for="goal-budget">Budget:</label>
      <input
        v-model="localGoal.budget"
        id="goal-budget"
        type="number"
        @input="updateGoal"
      />
      <br />
      <label for="goal-posts">Posts:</label>
      <input
        v-model="localGoal.posts"
        id="goal-posts"
        type="number"
        @input="updateGoal"
      />

      <label for="goal-posts-premium">Posts Premium:</label>
      <input
        v-model="localGoal.posts_premium"
        id="goal-posts-premium"
        type="number"
        @input="updateGoal"
      />
      <br />
      <div v-if="strategyType === 'video_view'">
        <label for="goal-view-3">View 3”:</label>
        <input
          v-model="localGoal.views_3s"
          id="goal-view-3"
          type="number"
          @input="updateGoal"
        />

        <label for="goal-view-15">View 15”:</label>
        <input
          v-model="localGoal.views_15s"
          id="goal-view-15"
          type="number"
          @input="updateGoal"
        />
        <br />
      </div>

      <div v-if="strategyType === 'image' || strategyType === 'video_reach'">
        <label for="goal-reach">Reach:</label>
        <input
          v-model="localGoal.reach"
          id="goal-reach"
          type="number"
          @input="updateGoal"
        />
        <br />
      </div>

      <label for="goal-impressions">Impressions:</label>
      <input
        v-if="strategyType === 'image' || strategyType === 'video_reach'"
        v-model="localGoal.impressions"
        id="goal-impressions"
        type="number"
        @input="updateGoal"
      />
      <div id="blocco_video_view" v-if="strategyType === 'video_view'">
        <input
          v-model="localGoal.impressions_min"
          id="goal-impressions-min"
          type="number"
          @input="updateGoal"
        />
        <input
          v-model="localGoal.impressions_max"
          id="goal-impressions-max"
          type="number"
          @input="updateGoal"
        />
      </div>
    </div>
  </div>
  <button class="infotronik_stategia_calcolagoal" @click="calculateValues">
    Calcola
  </button>
</template>

<script>
import postsTable from "@/assets/postsTable.json";
export default {
  name: "Goal",
  props: {
    goal: {
      type: Object,
      required: true,
    },
    strategyType: {
      type: String,
      required: true,
    },
    preferredViews: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localGoal: { ...this.goal }, // Crea una copia locale della prop
    };
  },
  methods: {
    updateGoal() {
      this.$emit("update:goal", this.localGoal); // Emetti un evento per informare il genitore delle modifiche
    },
    resetValues() {
      this.localGoal.reach = 0;
      this.localGoal.impressions = 0;
      this.localGoal.views_3s = 0;
      this.localGoal.views_15s = 0;
      this.localGoal.impressions_min = 0;
      this.localGoal.impressions_max = 0;
      this.localGoal.posts = 0;
      this.localGoal.posts_premium = 0;
      this.updateGoal(); // Aggiorna il goal dopo aver calcolato i valori
    },
    calculateValues() {
      const budget = this.localGoal.budget;

      // azzero?
      if (budget === 0) {
        this.resetValues();
        return;
      }

      // Controllo se il budget è valido
      if (isNaN(budget) || budget <= 0) {
        console.error("Invalid budget value:", this.localGoal.budget);
        return;
      }

      // Calcolo del reach
      let reach = (budget / 8) * 1000;
      if (budget >= 30000) {
        reach += reach * 0.18;
      } else if (budget >= 10000) {
        reach += reach * 0.15;
      }
      this.localGoal.reach = Math.round(reach);

      // Calcolo delle impressions
      let impressions = (budget / 7) * 1000;
      if (budget >= 30000) {
        impressions += impressions * 0.18;
      } else if (budget >= 10000) {
        impressions += impressions * 0.15;
      }
      this.localGoal.impressions = Math.round(impressions);

      // Calcolo delle views_3s
      let views_3s = budget / 0.04;
      if (budget >= 10000) {
        views_3s += views_3s * 0.1;
      }
      this.localGoal.views_3s = Math.round(views_3s);

      // Calcolo delle views_15s
      let views_15s = budget / 0.13;
      if (budget >= 10000) {
        views_15s += views_15s * 0.12;
      }
      this.localGoal.views_15s = Math.round(views_15s);

      // Calcolo delle impressions_min e impressions_max
      let impressions_min, impressions_max;
      if (this.preferredViews === "both") {
        impressions_min = Math.min(budget / 0.0125, budget / 0.008);
        impressions_max = Math.max(budget / 0.0125, budget / 0.008);
      } else if (this.preferredViews === "views_3s") {
        impressions_min = (budget / 0.0125) * 0.75;
        impressions_max = (budget / 0.0125) * 1.25;
      } else if (this.preferredViews === "views_15s") {
        impressions_min = (budget / 0.008) * 0.8;
        impressions_max = (budget / 0.008) * 1.2;
      }
      this.localGoal.impressions_min = Math.round(impressions_min);
      this.localGoal.impressions_max = Math.round(impressions_max);

      // Calcolo dei posts e posts_premium
      const closestBudget = Object.keys(postsTable).reduce((prev, curr) => {
        return Math.abs(curr - budget) < Math.abs(prev - budget) ? curr : prev;
      });
      this.localGoal.posts = postsTable[closestBudget].posts;
      this.localGoal.posts_premium = postsTable[closestBudget].posts_premium;

      this.updateGoal(); // Aggiorna il goal dopo aver calcolato i valori
    },
  },
  watch: {
    goal: {
      handler(newGoal) {
        this.localGoal = { ...newGoal };
      },
      deep: true,
    },
  },
  preferredViews: {
    handler() {
      this.calculateValues();
    },
    immediate: true,
  },
};
</script>

<style scoped>
.goal {
  margin-bottom: 1rem;
}
</style>
