<template>
  <div class="editor-block infotronik_quotes_bloccoeditors">
    <h3>{{ tag.tag.name }} - editor disponibili:</h3>
    <ul>
      <li v-for="editor in localEditors" :key="editor.id">
        <span
          ><i
            v-if="editor.editor.isPremium"
            class="lni lni-star"
            style="color: #2cbeff"
          ></i>
          {{ editor.editor.name }}
        </span>
        <div class="infotronik_seleditor_excel">
          <label class="pnt ns infotronik_seleditor_excel_preferito">
            Consigliato:
            <input type="checkbox" v-model="editor.preferred" />
          </label>
          <div class="infotronik_seleditor_excel_social">
            <label
              v-for="discriminator in Socials"
              :key="discriminator"
              class="pnt"
            >
              <input
                type="checkbox"
                :value="discriminator"
                v-model="editor.discriminators"
                @change="updateDiscriminators(editor, editor.discriminators)"
                class="pnt"
              />
              {{ discriminator }}
            </label>
          </div>
        </div>
        <button
          class="infotronik_seleditor_excel_rimuovi"
          @click="onDeselect(editor)"
        >
          Rimuovi
        </button>
      </li>
      <template v-if="backend_tag && groupedSocials">
        <div
          v-for="(socials, discriminator) in groupedSocials"
          :key="discriminator"
        >
          <h4>{{ discriminator }}</h4>
          <TagSocialElement
            v-for="social in filteredSocials(socials)"
            :key="social.socialId"
            :social="social"
            @list-select="onSelect"
            @update:preferred="updatePreferred(editor, $event)"
            @update:discriminators="updateDiscriminators(editor, $event)"
          ></TagSocialElement>
        </div>
      </template>
    </ul>
    <textarea
      v-model="localTagText"
      placeholder="Descrizione del target: (opzionale)"
    ></textarea>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import TagSocialElement from "@/components/quotes/TagSocialElement";
import { apiTagFunction } from "@/api/tags";
import { Socials } from "@/utils/Socials";

export default {
  name: "EditorBlock",
  props: {
    tag: {
      type: Object,
      required: true,
    },
  },
  components: {
    TagSocialElement,
  },
  setup(props, { emit }) {
    const backend_tag = ref(null);
    const groupedSocials = ref({});
    const localTagText = ref(props.tag.tagText);
    const localEditors = ref([...props.tag.editors]);

    const getTag = async () => {
      try {
        const response = await apiTagFunction.loadTagData(props.tag.tag.id);
        backend_tag.value = response;

        // Raggruppa i socials per discriminator
        groupedSocials.value = response.socials
          .filter((social) => social.enabled)
          .reduce((acc, social) => {
            const key = social.discriminator;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(social);
            return acc;
          }, {});
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(() => {
      sanitizeData();
      getTag();
    });

    const sanitizeData = () => {
      localEditors.value.forEach((editor) => {
        if (!Array.isArray(editor.discriminators)) {
          editor.discriminators = [];
        }
      });
    };

    watch(localTagText, (newTagText) => {
      emit("update:tag", { ...props.tag, tagText: newTagText });
    });

    watch(
      localEditors,
      (newEditors) => {
        emit("update:tag", { ...props.tag, editors: newEditors });
      },
      { deep: true }
    );

    const onSelect = (editor) => {
      //editor.selected = true;
      const order = localEditors.value.length + 1; // todo si può omettere, viene poi riorganizzato dal backend
      const element = {
        selected: true,
        editor: editor.social,
        preferred: editor.preferred,
        discriminators: editor.discriminators,
        order: order,
      };
      localEditors.value.push(element);
    };

    const onDeselect = (editor) => {
      editor.editor.selected = false;
      localEditors.value = localEditors.value.filter(
        (e) => e.editor.socialId !== editor.editor.socialId
      );
      // Aggiorna l'ordine degli editor rimanenti
      localEditors.value.forEach((e, index) => {
        e.order = index + 1;
      });
    };

    const filteredSocials = (socials) => {
      return socials.filter(
        (social) =>
          !localEditors.value.some(
            (editorObj) => editorObj.editor.socialId === social.socialId
          )
      );
    };

    const updatePreferred = (editor, newPreferred) => {
      editor.preferred = newPreferred;
      emit("update:tag", { ...props.tag, editors: localEditors.value });
    };

    const updateDiscriminators = (editor, newDiscriminators) => {
      editor.discriminators = Array.isArray(newDiscriminators)
        ? newDiscriminators
        : [];
      emit("update:tag", { ...props.tag, editors: localEditors.value });
    };

    return {
      backend_tag,
      groupedSocials,
      localTagText,
      localEditors,
      onSelect,
      onDeselect,
      filteredSocials,
      Socials,
      updatePreferred,
      updateDiscriminators,
    };
  },
};
</script>

<style scoped>
.editor-block {
  border: 1px solid #ccc;
  padding: 16px;
  margin-top: 16px;
  margin-bottom: 1rem;
}
</style>
