<template>
  <li class="layout-list-item" @click="onSelect">
    <div class="layout-list-item-btn flex-container p8 pl16">
      <div class="layout-list-item-title nomargin">
        <span>
          <i
            v-if="social.isPremium"
            class="lni lni-star"
            style="color: #2cbeff"
          ></i>
          {{
            social.name ? social.name + " (" + social.follower + ")" : "Editor"
          }}
        </span>
      </div>
    </div>
  </li>
</template>

<script>
import { watch } from "vue";
export default {
  emits: ["list-select"],
  props: {
    social: {
      type: Object,
      default: () => {},
    },
    preferred: {
      type: Boolean,
      default: false,
    },
    discriminators: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    const onSelect = () => {
      context.emit("list-select", props);
    };

    watch(
      () => props.preferred,
      (newVal) => {
        context.emit("update:preferred", newVal);
      }
    );

    watch(
      () => props.discriminators,
      (newVal) => {
        context.emit("update:discriminators", newVal);
      },
      { deep: true }
    );

    return {
      onSelect,
    };
  },
};
</script>

<style scoped>
.layout-list-item {
  cursor: pointer;
}
.layout-list-item:hover {
  background-color: #f0f0f0;
}
.layout-list-item-btn {
  display: flex;
  align-items: center;
}
.layout-list-item-title {
  margin: 0;
}
</style>
