<template>
  <div class="layout-content w100 create-quote-container h100 mockup-form">
    <div class="layout-content-header p24 pb0">
      <!-- <div class="mini-logo "></div>-->
      <h1 class="nomargin bebas pt24 infotronik_quotes_titolo">
        {{ headerText }}
      </h1>
    </div>

    <keep-alive>
      <Spinner v-if="isLoading" id="spinner" />
      <div v-else>
        <div class="flex-container flex-col pl24 pr24 pb24">
          <div class="flex-container flex-col infotronik_quotes_campagna">
            <div
              id="blocco_campagna"
              class="infotronik_quotes_campagna_campagna"
            >
              <label for="title">Nome campagna:</label><br />
              <input
                v-model="data.campaign.name"
                id="campaign_name"
                type="text"
                placeholder="Inserisci il nome della campagna"
                @blur="populatePageTitleIfEmpty"
              />
            </div>

            <div class="infotronik_quotes_campagna_advertiser">
              <AjaxSearchSelect
                :config="{
                  mode: 'single',
                  trackBy: 'userName',
                  label: 'userName',
                  valueProp: 'userName',
                }"
                label="Advertiser"
                :feedUrl="ApiEndPoints.USERS"
                :params="{
                  roles: 'Advertiser',
                }"
                @select="onAdvertiserSelected"
                @deselect="onAdvertiserDeselected"
                :selected="data?.campaign.advertiser || null"
                :className="'flex-container flex-col mb8 pl8 no-grow'"
              >
              </AjaxSearchSelect>
            </div>

            <hr class="infotronik_quotes_separatore" />

            <div id="blocco_strategie">
              <div
                v-for="(strategy, index) in data.strategies"
                :key="index"
                class="strategy-container"
              >
                <Strategy
                  class="strategy-container"
                  :strategy="strategy"
                  @update:strategy="updateStrategies(index, $event)"
                />

                <hr class="infotronik_quotes_separatore_interno" />

                <button
                  class="infotronik_stategia_rimuoviblocco"
                  @click="removeStrategy(index)"
                >
                  Rimuovi blocco
                </button>
              </div>
              <input
                class="innovativestudio_mockup_aibutton infotronik_quotes_bsbutton"
                type="button"
                value="+ Aggiungi blocco Budget & Strategia"
                @click="addStrategy"
              />
            </div>

            <hr class="infotronik_quotes_separatore" />

            <div
              id="blocco_discriminators"
              class="inotronik_quotes_selectdiscriminator"
            >
              <h3 class="bebas pl8 inotronik_quotes_selectdiscriminator_title">
                Social di Riferimento
              </h3>
              <div class="flex-container flex-col pl8">
                <label
                  v-for="discriminator in Socials"
                  :key="discriminator"
                  class="pnt"
                >
                  <input
                    type="checkbox"
                    :value="discriminator"
                    v-model="data.discriminators"
                    class="pnt"
                  />
                  {{ discriminator }}
                </label>
              </div>
            </div>

            <hr class="infotronik_quotes_separatore" />

            <div class="infotronik_quotes_bloccotarget">
              <AjaxSearchSelect
                :config="{
                  mode: 'tags',
                  trackBy: 'name',
                  label: 'name',
                  valueProp: 'name',
                }"
                :feedUrl="ApiEndPoints.TAGS"
                @select="onTagSelect"
                @deselect="onTagDeSelect"
                :selected="selectedTags"
                label="selezionare i target"
                :className="'flex-container flex-col mb8 pl8 no-grow'"
              >
              </AjaxSearchSelect>
              <div v-for="(tag, index) in data.tags" :key="tag.tag.name">
                <EditorBlock
                  :tag="tag"
                  @update:tag="updateTag(index, $event)"
                />
              </div>
            </div>
          </div>

          <hr class="infotronik_quotes_separatore" />

          <div id="blocco_post" class="infotronik_quotes_bloccotpost">
            <h3 class="bebas pl8">Post</h3>
            <div class="infotronik_quotes_bloccotpost_manuale_c">
              <!-- editor associato mockup -->
              <label for="editor">Editor associato:</label><br />
              <select
                class="quotes_editor_mockup_associato"
                v-model="data.postmockup.editor_id"
                v-if="availableEditors.length > 0"
              >
                <option
                  v-for="editor in availableEditors"
                  :key="editor.editor.socialId"
                  :value="editor.editor.socialId"
                >
                  {{ editor.editor.name }}
                </option>
              </select>
              <i class="quotes_editor_mockup_associato" v-else
                >Nessun editor disponibile</i
              >
              <!-- pagina del cliente mockup -->
              <br />
              <label for="title">Pagina Cliente:</label><br />
              <input
                v-model="data.postmockup.title"
                id="title"
                type="text"
                placeholder="Inserisci il titolo"
              />
            </div>
            <div class="infotronik_quotes_bloccotpost_manuale">
              <!-- parte di sinistra -->
              <div class="infotronik_quotes_bloccotpost_manuale_sx">
                <label for="body">Contenuto Post:</label><br />
                <textarea
                  v-model="data.postmockup.content"
                  id="body"
                  placeholder="Inserisci il contenuto del post"
                ></textarea>
                <br />
                <br />
                <label class="mockup-label">
                  Immagine:
                  <!--label for="image">Immagine:</label-->
                  <!--input type="file" id="image" @change="onImageChange" /-->
                  <FileUpload
                    :editing="true"
                    :fileurl="postImgUrl"
                    :className="'uploader-container flex-container flex-col flex-center-align'"
                    :IaImageDescription="data.postmockup.content"
                    :type="'image'"
                    @image-url-load="postImageSelected"
                    @image-deleted="ClearPostImage"
                  ></FileUpload>
                </label>
              </div>
              <!-- fine parte di destra -->
              <!-- parte di sinistra -->
              <div class="infotronik_quotes_bloccotpost_manuale_dx">
                <div class="infotronik_quotes_bloccotpost_manuale_dx_cp">
                  <EditorSelectionModal
                    v-if="showEditorSelectionModal"
                    :TagsEditors="groupedEditors"
                    @close="showEditorSelectionModal = false"
                    @ai-description-selected="handleCodyResponseSelected"
                  />
                  <input
                    id="editor-selection"
                    class="innovativestudio_mockup_aibutton"
                    type="button"
                    value="Genera con AI..."
                    @click="openModal('openEditorSelectionModal')"
                    :disabled="selectedEditorsCount == 0"
                  />
                </div>
                <br />
                <hr class="infotronik_quotes_separatore_interno" />
                <div class="infotronik_quotes_bloccotpost_manuale_sx_text">
                  <label for="body">Descrizione a lato: (opzionale)</label
                  ><br />
                  <textarea
                    v-model="data.postmockup.sideText"
                    id="body"
                    placeholder="Inserisci la descrizione completa, da mostrare a lato del post"
                  ></textarea>
                </div>
                <!-- fine parte di sinistra -->
              </div>
            </div>
          </div>
          <input
            id="save-quote"
            type="submit"
            :value="buttonLabel"
            class="w100 noborder nopadding bebas f24"
            @click="saveQuote"
            :disabled="!isFormValid"
          />
        </div>
      </div>
    </keep-alive>
  </div>
</template>

<script>
import { reactive, ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import AjaxSearchSelect from "@/components/formelements/AjaxSearchSelect";
import { ApiEndPoints } from "@/api/Endpoints";
import Strategy from "@/components/quotes/Strategy.vue";
import EditorBlock from "@/components/quotes/EditorBlock.vue";
import FileUpload from "@/components/FileUpload";
import axios from "axios";
import Spinner from "@/components/Spinner.vue";
import EditorSelectionModal from "@/components/quotes/EditorSelectionModal.vue";
import { Socials } from "@/utils/Socials";
import Placeholders from "@/assets/placeholders.json";

export default {
  name: "EditQuote",
  components: {
    AjaxSearchSelect,
    Strategy,
    EditorBlock,
    FileUpload,
    Spinner,
    EditorSelectionModal,
  },
  methods: {
    populatePageTitleIfEmpty() {
      if (!this.data.postmockup.title) {
        this.data.postmockup.title = this.data.campaign.name;
      }
    },
  },
  computed: {
    buttonLabel() {
      return this.isEditMode ? "Aggiorna preventivo" : "Genera preventivo";
    },
    headerText() {
      return this.isEditMode ? "Modifica preventivo" : "Nuovo preventivo";
    },
    postImgUrl() {
      return this.data.postmockup.postImageData || "";
    },
  },
  created() {
    this.routes = ref([{ name: "Preventivi", path: "/quotes" }]);
    this.emitter.emit("breadcrumb", this.routes);
  },
  setup() {
    const isLoading = ref(false);
    const route = useRoute();
    const router = useRouter();
    const componentKey = ref(0);
    const showEditorSelectionModal = ref(false);
    const isEditMode = ref(false);
    const data = reactive({
      campaign: {
        name: "",
        advertiser: null,
      },
      cod: "",
      discriminators: [],
      postmockup: {
        title: "",
        editor_id: null,
        content: "",
        postImageData: null,
        sideText: "",
      },
      tags: [],
      strategies: [],
      ver: 10000, // Versioning del preventivo. Serve per gestire le modifiche future
    });

    onMounted(() => {
      if (route.params.id) {
        isEditMode.value = true;
        // Carica i dati del preventivo esistente
        loadQuote(route.params.id);
      } else {
        data.discriminators = ["Facebook", "Instagram"];
        data.postmockup.sideText = Placeholders.quote.postmockup.content;
      }
    });

    const handleCodyResponseSelected = (response) => {
      if (response) {
        data.postmockup.content = response.content;
        data.postmockup.editor_id = response.socialId;
      }
    };

    const onAdvertiserSelected = (advertiser) => {
      data.campaign.advertiser = advertiser;
    };

    const onAdvertiserDeselected = () => {
      data.campaign.advertiser = null;
    };

    const postImageSelected = (imgUrl) => {
      data.postmockup.postImageData = imgUrl;
    };

    const onTagSelect = async (tag) => {
      data.tags.push(NewTag(tag));
    };

    const onTagDeSelect = (tag) => {
      const index = data.tags.findIndex((t) => t.tag.name === tag.name);
      if (index > -1) {
        CheckPostMockupEditor(data.tags[index], true);
        data.tags.splice(index, 1);
      }
    };

    const NewTag = (tag) => {
      return {
        tag: tag,
        tagText: tag.description,
        order: 0,
        editors: [],
      };
    };

    const updateTag = (index, updatedTag) => {
      data.tags[index] = updatedTag;
      // Verifico se l'editor selezionato nel mockup esiste ancora o se è vuoto
      CheckPostMockupEditor(updatedTag);
    };

    const CheckPostMockupEditor = (tag, reverseCheck = false) => {
      // Verifica se l'editor selezionato nel mockup è ancora disponibile
      if (data.postmockup.editor_id !== null) {
        const editorExists = tag.editors.some(
          (editor) => editor.editor.socialId === data.postmockup.editor_id
        );
        if (reverseCheck) {
          if (editorExists) {
            data.postmockup.editor_id = null;
          }
        } else {
          if (!editorExists) {
            data.postmockup.editor_id = null;
          }
        }
      }
      // Verifico se devo preselezionare un editor (campo vuoto)
      if (data.postmockup.editor_id === null) {
        const firstEditor = tag.editors[0];
        if (firstEditor) {
          data.postmockup.editor_id = firstEditor.editor.socialId;
        }
      }
    };

    const ClearPostImage = () => {
      data.postmockup.postImageData = null;
    };

    const addStrategy = () => {
      const newStrategy = {
        name: "Budget & Strategia",
        order: data.strategies.length + 1,
        goals: [],
        footerText: "",
        type: "image",
        preferred_views: "both",
      };
      data.strategies.push(newStrategy);
      updateStrategyOrder();
    };

    const removeStrategy = (index) => {
      data.strategies.splice(index, 1);
      updateStrategyOrder();
    };

    const updateStrategies = (index, updatedStrategy) => {
      if (updatedStrategy.order < 1) {
        updatedStrategy.order = 1;
      }
      data.strategies[index] = updatedStrategy;
      updateStrategyOrder();
    };

    const updateStrategyOrder = () => {
      data.strategies.sort((a, b) => a.order - b.order);
      data.strategies.forEach((strategy, index) => {
        strategy.order = index + 1;
      });
    };

    const openModal = (modalType) => {
      closeModals();
      const lowerCaseModalType = modalType.toLowerCase();
      switch (lowerCaseModalType) {
        case "openeditorselectionmodal":
          showEditorSelectionModal.value = true;
          break;
        default:
          console.warn(`Modal type "${modalType}" is not recognized.`);
      }
    };

    const closeModals = () => {
      showEditorSelectionModal.value = false;
    };

    const validateForm = () => {
      // console.log("---FORM-VALIDATION---");
      //console.log("Advertiser:", data.campaign.advertiser);
      //console.log("Title valid:", (data.postmockup.title?.trim().length ?? 0) > 0);
      // console.log(
      //   "Content:",
      //   (data.postmockup.content?.trim().length ?? 0) > 0
      // );
      // console.log("Post image url:", data.postmockup.postImageData);
      // console.log("Strategies:", data.strategies);
      // console.log("Strategies valid:", strategiesValid.value);
      // console.log("Editors valid:", editorsValid.value);
      //console.log("Editors:", groupedEditors.value);
      //console.log(selectedEditorsCount.value);
      // console.log("Discriminators:", data.discriminators);
      return (
        data.discriminators.length > 0 &&
        data.campaign.name &&
        data.campaign.advertiser &&
        data.postmockup.editor_id &&
        data.postmockup.title &&
        data.postmockup.content &&
        data.tags.length > 0 &&
        strategiesValid.value &&
        editorsValid.value
      );
    };

    const validateAllStrategies = () => {
      // console.log("---STRATEGIES-VALIDATION---");
      // console.log("Validatin strategies:");
      // console.log("strategies.length", (data.strategies?.length ?? 0) > 0);
      // console.log(
      //   "strategies.every",
      //   data.strategies.every((strategy) => {
      //     console.log("strategy.name", (strategy.name?.trim().length ?? 0) > 0);
      //     console.log(
      //       "strategy.goals.length",
      //       (strategy.goals?.length ?? 0) > 0
      //     );
      //     return (
      //       (strategy.name?.trim().length ?? 0) > 0 &&
      //       (strategy.goals?.length ?? 0) > 0 &&
      //       strategy.goals.every((goal) => {
      //         console.log("goal.budget", goal.budget > 0);
      //         return goal.budget > 0;
      //       })
      //     );
      //   })
      // );

      return (
        (data.strategies?.length ?? 0) > 0 &&
        data.strategies.every((strategy) => {
          return (
            (strategy.type?.trim().length ?? 0) > 0 &&
            (strategy.name?.trim().length ?? 0) > 0 &&
            (strategy.goals?.length ?? 0) > 0 &&
            strategy.goals.every((goal) => {
              return goal.budget > 0;
            })
          );
        })
      );
    };

    const validateAllEditors = () => {
      return (
        data.tags.length > 0 &&
        data.tags.every((tag) => {
          return (tag.editors?.length ?? 0) > 0;
        })
      );
    };

    const availableEditors = computed(() => {
      return data.tags.flatMap((tag) => tag.editors);
    });
    const isFormValid = computed(() => validateForm());
    const strategiesValid = computed(() => validateAllStrategies());
    const editorsValid = computed(() => validateAllEditors());
    const selectedTags = computed(() => {
      return data.tags.map((tagObj) => tagObj.tag);
    });
    const selectedEditorsCount = computed(() => {
      return Object.values(groupedEditors.value).reduce((acc, editors) => {
        return acc + (editors?.length ?? 0);
      }, 0);
    });
    const groupedEditors = computed(() => {
      return data.tags.reduce((acc, tagObj) => {
        acc[tagObj.tag.name] = tagObj.editors;
        return acc;
      }, {});
    });

    const loadQuote = async (cod) => {
      isLoading.value = true;
      try {
        const response = await axios.get(ApiEndPoints.QUOTES + `/${cod}`);
        const quote = response.data;
        transformJsonDataFromBackend(quote);
      } catch (error) {
        console.error("Errore nel caricamento del preventivo:", error);
      } finally {
        isLoading.value = false;
      }
    };

    const saveQuote = async () => {
      if (!isFormValid.value) {
        // Prevent fuckedup html
        console.log("Invalid form data.");
        return;
      }
      isLoading.value = true; // Disable UI
      let req_error = false;
      try {
        let result;

        // Preparazione preventivo per backend
        let quote = PrepareJsonDataForBackend();

        if (isEditMode.value) {
          // Edit in corso (PUT)
          result = await axios.put(
            ApiEndPoints.QUOTES + `/${route.params.id}`,
            {
              jquote: quote,
            }
          );
        } else {
          result = await axios.post(ApiEndPoints.QUOTES, {
            jquote: quote,
          });
        }
        if (result.status == 200 && result.data.length > 0) {
          // Reindirizza alla lista dei preventivi
          router.push("/quotes");
          //this.emitter.emit("menu", "/quotes");
        } else {
          req_error = true;
        }
      } catch (error) {
        console.error("Errore:", error);
        req_error = true;
      } finally {
        isLoading.value = false; // Re-enable UI
        if (req_error) {
          // Mostra errore
          alert("Errore durante il salvataggio del preventivo.");
        }
      }
    };

    const PrepareJsonDataForBackend = () => {
      let quote = {
        discriminators: data.discriminators,
        campaign: { ...data.campaign },
        cod: data.cod,
        strategies: data.strategies,
        postmockup: data.postmockup,
        tags: data.tags,
        ver: data.ver,
      };

      // rioordinamento degli elementi (da 1 in poi)
      let tagOrder = 0;
      quote.tags.forEach((tag) => {
        tag.order = ++tagOrder;
        let editorOrder = 0;
        tag.editors.forEach((editor) => {
          editor.order = ++editorOrder;
        });
      });

      return quote;
    };

    // trasformo il json ricevuto dal backend in un formato utilizzabile dal frontend
    const transformJsonDataFromBackend = (json) => {
      data.discriminators = json.discriminators ?? [];
      data.campaign = json.campaign;
      data.cod = json.cod;
      data.strategies = json.strategies;
      data.postmockup = json.postmockup;
      data.tags = json.tags;
      data.ver = json.ver;
    };

    return {
      isEditMode,
      handleCodyResponseSelected,
      ApiEndPoints,
      data,
      onTagSelect,
      onTagDeSelect,
      addStrategy,
      removeStrategy,
      updateStrategies,
      closeModals,
      openModal,
      componentKey,
      saveQuote,
      isFormValid,
      postImageSelected,
      isLoading,
      onAdvertiserSelected,
      onAdvertiserDeselected,
      showEditorSelectionModal,
      editorsValid,
      selectedEditorsCount,
      updateTag,
      NewTag,
      selectedTags,
      groupedEditors,
      availableEditors,
      ClearPostImage,
      Socials,
    };
  },
};
</script>

<style scoped>
.create-quote-container {
  max-height: 100vh;
  overflow-y: auto;
  margin-bottom: 40px !important;
}

.strategy-container {
  margin-bottom: 1rem;
}
.header-content-grid {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.2fr 2.8fr;
  gap: 0px 0px;
  grid-template-areas:
    "header"
    "content";
  & .card-header {
    grid-area: header;
  }
  & .card-content {
    grid-area: content;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.mockup-label {
  max-width: 49%;
}
#save-quote:disabled {
  cursor: not-allowed;
}

#editor-selection:disabled {
  cursor: not-allowed;
}
</style>
