<template>
  <teleport to="#overlay">
    <div class="overlay w100 h100 abs">
      <div class="overlay-panel rel pb24">
        <form
          class="flex-container flex-col min-height-inherit"
          id="panel"
          @submit.prevent="onSubmit"
        >
          <div class="panel-header p24">
            <div class="mini-logo"></div>
            <h2 class="panel-title nomargin bebas">Genera Contenuto</h2>
          </div>

          <keep-alive>
            <div
              v-if="step === 0"
              class="flex-container flex-col pl24 pr24 pb24"
            >
              <div
                v-for="(editors, tagName) in filteredTagsEditors"
                :key="tagName"
                class="tag-section infotronik_quotes_popup_tagselection"
              >
                <h3>{{ tagName }}</h3>
                <div
                  class="editor-selection infotronik_quotes_popup_tagselection_sel"
                >
                  <div
                    v-for="editor in editors"
                    :key="editor.publisherId"
                    class="editor-item infotronik_quotes_popup_tagselection_item"
                  >
                    <input
                      type="checkbox"
                      :id="`editor-${editor.editor.socialId}`"
                      :value="editor"
                      v-model="selectedEditors"
                      :disabled="isEditorDisabled(editor)"
                      class="pnt"
                    />
                    <label
                      :for="`editor-${editor.editor.socialId}`"
                      class="pnt"
                      >{{ editor.editor.name }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </keep-alive>

          <keep-alive>
            <div
              v-if="step === 1"
              class="flex-container flex-col pl24 pr24 pb24"
            >
              <textarea
                class="flex-container flex-col add-description pb16 infotronik_quotes_popup_textarea"
                v-model="description"
                placeholder="Inserisci una breve descrizione"
              ></textarea>
            </div>
          </keep-alive>

          <keep-alive>
            <div
              v-if="step === 2"
              class="flex-container flex-col pl24 pr24 pb24"
            >
              <div
                v-if="isLoading"
                class="flex-container flex-end-align spinner-container infotronik_quotes_popup_spinnercontainer"
              >
                <Spinner id="spinner" />
              </div>
              <div
                v-else
                class="cody_response_container_list infotronik_quotes_popup_containerlist"
              >
                <div
                  v-for="(response, index) in codyResponses"
                  :key="index"
                  class="innovativestudio_codyresponse_element"
                  @click="selectResponse(index)"
                  :class="{ 'ai-selected': selectedIndex === index }"
                >
                  <h6>Origine: {{ response.socialName }}</h6>
                  <p>{{ response.content }}</p>
                </div>
              </div>
            </div>
          </keep-alive>

          <div class="flex-container panel-footer abs w100">
            <div class="flex-container">
              <input
                type="reset"
                :value="step === 0 ? 'Annulla' : 'Indietro'"
                class="w100 noborder nopadding bebas"
                @click.prevent="prev"
              />
              <input
                type="submit"
                :value="step === 2 ? 'Conferma' : 'Avanti'"
                class="w100 noborder nopadding bebas"
                :disabled="preventNext"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </teleport>
</template>
<script>
import { ref, computed } from "vue";
import axios from "axios";
import Spinner from "@/components/Spinner.vue";
import { ApiEndPoints } from "@/api/Endpoints";

export default {
  name: "EditorSelectionModal",
  props: {
    TagsEditors: {
      type: Object,
      required: true,
    },
  },
  emits: ["close", "ai-description-selected"],
  components: {
    Spinner,
  },
  setup(props, { emit }) {
    const step = ref(0);
    const selectedEditors = ref([]);
    const description = ref("");
    const isLoading = ref(false);
    const codyResponses = ref([]);
    const selectedIndex = ref(null);

    const isEditorDisabled = (editor) => {
      return (
        selectedEditors.value.length >= 4 &&
        !selectedEditors.value.includes(editor)
      );
    };

    const closeModal = () => {
      emit("close");
    };

    const GetSelectedEditorId = () => {
      if (selectedIndex.value == null) return null;
      const selectedResponse = codyResponses.value[selectedIndex.value];
      const selectedEditor = selectedEditors.value.find(
        (editor) => editor.editor.name === selectedResponse.socialName
      );
      return selectedEditor ? selectedEditor.editor.socialId : null;
    };

    const GenerateContent = async () => {
      isLoading.value = true;
      try {
        const responses = await GetCodyResponses(selectedEditors.value);
        codyResponses.value = responses;
      } catch (error) {
        console.error(error);
      } finally {
        isLoading.value = false;
      }
    };

    const GetCodyResponses = async (validSocials) => {
      description.value = description.value.trim();
      let promises = validSocials.map((social) =>
        axios.post(ApiEndPoints.AI + "/createcontent", {
          socialName: social.editor.name,
          description: description.value,
          maxLen: 349,
        })
      );

      let responses = await Promise.all(promises);

      return responses.map((res) => {
        if (res instanceof Error) {
          return { error: true, message: res.message };
        } else {
          res.data.error = false;
          return res.data;
        }
      });
    };

    const onSubmit = async () => {
      if (isLoading.value) return; // prevent double click
      if (step.value === 2) {
        try {
          const selectedResponse = codyResponses.value[selectedIndex.value];
          // Aggiungo a selectedResponse l'id del social selezionato
          selectedResponse.socialId = GetSelectedEditorId();
          emit("ai-description-selected", selectedResponse);
          closeModal();
        } catch (error) {
          console.error("Error during API calls:", error);
        }
      } else if (step.value === 1) {
        next();
        await GenerateContent();
      } else {
        next();
      }
    };

    const selectResponse = (index) => {
      selectedIndex.value = index;
    };

    const preventNext = computed(() => {
      if (step.value === 0 && selectedEditors.value.length > 0) {
        return false;
      }
      if (step.value === 1 && description.value.trim().length > 0) {
        return false;
      }
      if (step.value === 2 && selectedIndex.value !== null) {
        return false;
      }
      return true;
    });

    const filteredTagsEditors = computed(() => {
      return Object.fromEntries(
        Object.entries(props.TagsEditors).filter(
          ([, editors]) => editors.length > 0
        )
      );
    });

    const maxStep = 2;
    const next = () => {
      if (step.value >= maxStep) return;
      step.value++;
    };

    const prev = () => {
      if (step.value === 0) {
        closeModal();
      } else {
        step.value--;
      }
    };

    return {
      step,
      selectedEditors,
      description,
      isLoading,
      codyResponses,
      selectedIndex,
      isEditorDisabled,
      closeModal,
      onSubmit,
      selectResponse,
      preventNext,
      next,
      prev,
      filteredTagsEditors,
    };
  },
};
</script>

<style lang="postcss">
.flex-container {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-grow {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}

.overflow-auto {
  overflow: auto;
}

.ai-selected {
  background: #fffbe0;
}
</style>
